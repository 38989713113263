import {
  sendPasswordResetEmail as _sendPasswordResetEmail,
  signOut as doSignOut,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";

export async function signIn(email, password) {
  const auth = getAuth();
  await signInWithEmailAndPassword(auth, email, password);
}

export async function signOut() {
  const auth = getAuth();
  await doSignOut(auth);
}

export async function sendPasswordResetEmail(email) {
  const auth = getAuth();
  const config = useRuntimeConfig();
  await _sendPasswordResetEmail(auth, email, {
    url: config.public.appUrl,
  });
}
